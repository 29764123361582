import request from '@/utils/request'

// 商品列表
export const getProducts = params => {
  return request({
    url: '/pc/get_products',
    params
  })
}

// 商品详情
export const getProductDetail = params => {
  return request({
    url: '/pc/detail',
    params
  })
}

// 获取购物车商品列表
export const getCartList = data => {
  return request({
    url: '/goods_cart/index',
    data,
    method: 'post',
  })
}

// 加入购物车
export const addCart = data => {
  return request({
    url: '/goods_cart/add',
    data,
    method: 'post',
  })
}

// 删除购物车
export const deleteCart = data => {
  return request({
    url: '/goods_cart/delete',
    data,
    method: 'post',
  })
}