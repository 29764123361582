<template>
  <div class="login">
    <p class="title">{{formType === 'login' ? '账号登录' : '注册账号'}}</p>
    <div class="form-group">
      <div class="input-block">
        <input type="text" placeholder="请输入账号" v-model="form.account">
      </div>
      <div class="input-block">
        <input type="password" placeholder="请输入密码" v-model="form.password">
      </div>
      <div class="input-block" v-if="formType != 'login'">
        <input type="password" placeholder="请再次输入密码" v-model="form.repassword">
      </div>
      <div class="acea-row">
        <div class="flex-1 input-block">
          <input type="text" placeholder="请输入验证码" v-model="form.captcha">
        </div>
        <a href="javascript:;" class="code" @click="changeCaptcha" v-if="sessionId">
          <img :src="captcha" alt="">
        </a>
      </div>
      <div class="isAgree acea-row">
        <el-checkbox v-model="isArgeement"></el-checkbox>
        <div class="agree">
          我已阅读并同意<span class="agreement" @click="showUserAgreement">《用户协议》</span>和<span class="agreement" @click="showPrivacyAgreement">《隐私协议》</span>
        </div>
      </div>
      <button type="button" class="submit-block" @click="login">{{formType === 'login' ? '登录' : '注册'}}</button>
    </div>
    <a href="javascript:;" class="change" @click="changeForm">
      {{formType === 'login' ? '没有账号？去注册' : '已有账号，去登录'}}
    </a>
  </div>

  <el-dialog :title="agreementTitle" v-model="isShowAgreement" :center="true" custom-class="limited-height">
    <div v-html="agreementContent"></div>
  </el-dialog>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { getSessionId, userLogin, userRegister } from '@/api/user'
import { ElCheckbox, ElDialog, ElMessage } from 'element-plus';
import 'element-plus/dist/index.css'

export default {
  name: 'loginPage',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    // 表单类型
    const formType = ref('login')
    // 时间戳
    const baseUrl = location.origin

    // 获取session_id
    const sessionId = ref('')
    const captcha = ref('')
    const loadSessionId = async () => {
      const { data } = await getSessionId()
      sessionId.value = data
      changeCaptcha()
    }
    loadSessionId()

    // 登录|注册
    const loading = ref(false);
    const isShowAgreement = ref(false);
    const isArgeement = ref(false);
    const agreementTitle = ref('隐私协议');
    const agreementContent = ref('');
    const form = reactive({
      account: '',
      password: '',
      repassword: '',
      captcha: ''
    })
    const login = async () => {
      if (!isArgeement.value) {
        ElMessage({
          message: '请确认阅读用户协议',
          type: 'error'
        });
        return;
      }
      const valid = validateForm()
      if(valid === true) {
        if(!loading.value) {
          loading.value = true

          try {
            let result = null

            if(formType.value === 'login') {
              result = await userLogin({ ...form, session_id: sessionId.value })
            } else {
              result = await userRegister({ ...form, session_id: sessionId.value })
            }

            await store.commit('user/SET_PROFILE', result.data.userinfo)
            await router.replace(route.query.redirect_url || '/')
          } finally {
            changeCaptcha();
            loading.value = false
          }
        }
      } else {
        ElMessage({
          message: valid,
          type: 'error'
        });
      }
    }
    const validateForm = () => {
      if(!form.account) return '账号不能为空'
      if(!form.password) return '密码不能为空'
      if(!form.captcha) return '验证码不能为空'
      if(formType.value !== 'login') {
        if(!form.repassword) return '重复密码不能为空'
        if(form.repassword !== form.password) return '两次密码输入不一致'
      }
      return true
    }

    // 切换验证码
    const changeCaptcha = () => {
      captcha.value = `${baseUrl}/api/user/code?session_id=${sessionId.value}&type=${formType.value}&time=${+new Date()}`
    }

    // 切换表单
    const changeForm = () => {
      formType.value = formType.value === 'login' ? 'register' : 'login'
      changeCaptcha()

      for(let key in form) {
        form[key] = ''
      }
    }

    // 显示用户协议
    const showUserAgreement = () => {
      isShowAgreement.value = true;
      agreementTitle.value = '用户协议';
      agreementContent.value = store.getters.appInfo.userAgreement;
    }

    // 显示隐私协议
    const showPrivacyAgreement = () => {
      isShowAgreement.value = true;
      agreementTitle.value = '隐私协议';
      agreementContent.value = store.getters.appInfo.privacyAgreement;
    }

    return {
      isShowAgreement,
      isArgeement,
      agreementTitle,
      agreementContent,
      formType,
      sessionId,
      form,
      captcha,
      login,
      changeCaptcha,
      changeForm,
      showUserAgreement,
      showPrivacyAgreement
    }
  },
  components: {
    [ElCheckbox.name]: ElCheckbox,
    [ElDialog.name]: ElDialog,
  }
}
</script>

<style lang="less">
.login {
  width: 500px;
  background-color: white;
  box-shadow: 0 0 10px rgba(55, 55, 55, .05);
  padding: 70px 0;
  text-align: center;
  margin-left: auto;
  margin-top: 50px;
  .title {
    font-size: 20px;
    margin-bottom: 35px;
  }
  .form-group {
    padding: 0 50px;
    margin-bottom: 35px;
    .input-block {
      width: 100%;
      height: 55px;
      margin-bottom: 35px;
      border: 1px solid #ddd;
      padding: 0 15px;
      input {
        width: 100%;
        height: 100%;
      }
    }
    .code {
      width: 120px;
      height: 55px;
      margin-left: 15px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .submit-block {
      width: 100%;
      height: 55px;
      font-size: 16px;
      color: white;
      background-color: #e93323;
      border: 0;
      cursor: pointer;
    }
    .isAgree {
      margin-top: -25px;
      margin-bottom: 10px;
      
      .agree {
        margin-left: 6px;
        color: #999;
        display: inline-flex;
        align-items: center;
      }

      .agreement {
        color: #e93323;
        cursor: pointer;
      }
    }
  }
  .change {
    color: #e93323;
  }
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  border-color: #ff0000 !important;
  background-color: #ff0000 !important;
}
.el-checkbox__inner:hover {
  border-color: #ff0000 !important;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #ff0000 !important;
}

.limited-height .el-dialog__body {
  max-height: 500px;
  overflow-y: auto;
}
</style>
