<template>
  <div class="product-detail" v-if="goods">
    <div class="block acea-row">
      <div class="picture">
        <img :src="goods.absolute_pic" alt="">
      </div>
      <div class="acea-row row-column-around flex-1 goods">
        <div class="title">{{goods.goods_name}}</div>
        <div class="acea-row row-middle">
          <div class="label">价格</div>
          <div class="price">
            <strong>￥{{goods.price}}</strong>
            <del>￥{{goods.original_price}}</del>
          </div>
        </div>
        <div class="acea-row row-middle">
          <div class="label">库存</div>
          <div class="store" style="font-size: 16px">
            {{goods.stock}}
          </div>
        </div>
        <div class="acea-row row-middle">
          <div class="label">数量</div>
          <div class="acea-row row-middle step">
            <a href="javascript:;" class="handle" @click="changeNumber(-1)">-</a>
            <div class="input">
              <input type="text" readonly :value="number">
            </div>
            <a href="javascript:;" class="handle" @click="changeNumber(+1)">+</a>
          </div>
        </div>
        <div>
          <button type="button" class="add-cart" @click="doAddCart">加入购物车</button>
          <button type="button" class="payment" @click="toPreviewOrder">立即购买</button>
        </div>
      </div>
    </div>
    <div class="block">
      <div class="section-name">商品详情</div>
      <div class="desc" v-html="goods.details"></div>
    </div>

    <van-overlay :show="show">
      <div class="address">
        <div class="title">请填写收货地址</div>

        <el-form-item label="姓名">
          <el-input name="name" v-model="form.name" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input name="mobile" v-model="form.mobile" placeholder="请输入电话"></el-input>
        </el-form-item>
        <el-form-item label="地址">
          <el-input name="address" v-model="form.address" placeholder="请输入详细地址,例如：广东省深圳市南山区XX街道XXX"></el-input>
        </el-form-item>
        
        <el-form-item class="submit-order">
          <el-button type="primary" @click="toPreviewOrder">去支付</el-button>
          <el-button @click="cancelOrderSubmit">取消</el-button>
        </el-form-item>

        <div style="clear: both;"></div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { Overlay } from 'vant';
import { ElForm, ElFormItem, ElInput, ElButton } from 'element-plus';
import 'element-plus/dist/index.css'
import { useRoute, useRouter } from 'vue-router'
import { getProductDetail, addCart } from '@/api/product'
import { preOrderInfo } from '@/api/order'
import Toast from '@/components/toast/toast'

export default {
  name: 'productDetailPage',
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const show = ref(false);
    const form = reactive({
      address: '',
      name: '',
      mobile: '',
    });
    var isGetPrevAddress = false;

    // 获取商品详情
    const goods = ref(null)
    getProductDetail({ id: route.params.id }).then(res => {
      goods.value = res.data
    })

    // 数量操作
    const number = ref(1)
    const changeNumber = step => {
      const value = number.value + step - 0
      if(value >= 1 && value <= goods.value.stock) {
        number.value = value
      }
    }

    const showOrderInput = async() => {
      show.value = true;
      if (!isGetPrevAddress) {
        // 获取上一次订单信息
        isGetPrevAddress = true;

        preOrderInfo().then(res => {
          form.address = res.data.address;
          form.name = res.data.name;
          form.mobile = res.data.mobile;
        }).catch(() => {
          isGetPrevAddress = false;
        })
      }
    }

    const doAddCart = async() => {
      addCart({
        goods_id: route.params.id,
        num: number.value,
      }).then(res => {
        store.commit('cart/ADD_CART_GOODS_NUM', number.value)
        Toast({ message: '加入购物车成功' })
      }).catch(() => {
        isGetPrevAddress = false;
      });
    }

    const toPreviewOrder = async () => {
      await router.push({
        name: 'orderPreview',
        query: {
          productId: route.params.id, 
          cartNum: number.value,
          name: form.name,
          mobile: form.mobile,
          address: form.address,
        }
      });
    }

    const cancelOrderSubmit = async () => {
      show.value = false;
    }

    return {
      goods,
      number,
      show,
      form,
      changeNumber,
      showOrderInput,
      doAddCart,
      cancelOrderSubmit,
      toPreviewOrder
    }
  },
  components: {
    [Overlay.name]: Overlay,
    [ElForm.name]: ElForm,
    [ElInput.name]: ElInput,
    [ElButton.name]: ElButton,
    [ElFormItem.name]: ElFormItem
  }
}
</script>

<style lang="less" scoped>
.product-detail {
  .block {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .picture {
    width: 320px;
    height: 320px;
    margin-right: 30px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .goods {
    .title {
      font-size: 22px;
    }
    .label {
      width: 60px;
      font-size: 16px;
    }
    .price {
      strong {
        font-size: 22px;
        color: red;
        margin-right: 10px;
      }
      del {
        font-size: 14px;
        color: #aaa;
      }
    }
    .store {
      font-size: 16px;
    }
    .step {
      height: 40px;
      border: 1px solid #ddd;
      border-radius: 4px;
      .handle {
        width: 44px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        font-size: 20px;
        color: #999;
      }
      .input {
        width: 64px;
        height: 38px;
        border-right: 1px solid #ddd;
        border-left: 1px solid #ddd;
        padding: 0 10px;
        input {
          width: 100%;
          height: 100%;
          text-align: center;
          background-color: transparent;
        }
      }
    }
    .add-cart {
      width: 160px;
      height: 50px;
      font-size: 16px;
      color: white;
      background-color: #e93323;
      border-radius: 4px;
      border: 0;
      cursor: pointer;
      display: inline-block;
      margin-right: 20px;
    }
    .payment {
      width: 160px;
      height: 50px;
      font-size: 16px;
      color: red;
      background-color: #FFFFFF;
      border: 1px solid #ff0000;
      border-radius: 4px;
      cursor: pointer;
      display: inline-block;
    }
  }
  .section-name {
    position: relative;
    font-size: 18px;
    margin-bottom: 20px;
    padding-left: 13px;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 3px;
      height: 80%;
      background-color: #e93323;
    }
  }
}

.address {
  width: 50%;
  margin: auto;
  margin-top: 10%;
  background: #fff;
  padding: 10px 40px;
  .title {
    margin-bottom: 10px;
    text-align: center;
    font-size: 20px;
  }
  .submit-order {
    margin: 20px 10px 10px 0;
    float: right;
  }
}
</style>