import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '',
    redirect: '/product',
    component: () => import('@/layouts'),
    children: [
      {
        path: '/product',
        name: 'productPage',
        component: () => import('@/views/product'),
        meta: {
          title: '商品'
        }
      },
      {
        path: '/product/detail/:id',
        name: 'productDetailPage',
        component: () => import('@/views/product/detail'),
        meta: {
          title: '商品详情'
        }
      },
      {
        path: '/goods_cart',
        name: 'goodsCart',
        component: () => import('@/views/product/goods_cart'),
        meta: {
          title: '购物车列表'
        }
      },
      {
        path: '/order',
        name: 'orderPage',
        component: () => import('@/views/order/index'),
        meta: {
          title: '订单列表'
        }
      },
      {
        path: '/order/preview',
        name: 'orderPreview',
        component: () => import('@/views/order/preview'),
        meta: {
          title: '提交订单'
        }
      },
      {
        path: '/order/detail',
        name: 'orderDetail',
        component: () => import('@/views/order/detail'),
        meta: {
          title: '订单详情'
        }
      },
      {
        path: '/payment/:orderSn',
        name: 'paymentPage',
        component: () => import('@/views/payment'),
        meta: {
          title: '支付'
        }
      },
      {
        path: '/payment/:orderSn/detail/:payType/:amount',
        name: 'paymentDetailPage',
        component: () => import('@/views/payment/detail'),
        meta: {
          title: '支付'
        }
      },
      {
        path: '/login',
        name: 'loginPage',
        component: () => import('@/views/login'),
        meta: {
          title: '登录'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    return { left: 0, top: 0 }
  },
  routes
})

export default router
