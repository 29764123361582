import request from '@/utils/request'

// 获取历史消息
export const getIndex = params => {
  return request({
    url: '/chat_message/index',
    params
  })
}

// 获取未读消息
export const getNotReadIndex = params => {
  return request({
    url: '/chat_message/not_read_index',
    params
  })
}

// 获取未读消息数量
export const getNotReadCount = params => {
  return request({
    url: '/chat_message/not_read_count',
    params
  })
}

// 发送消息
export const sendMessage = data => {
  return request({
    url: '/chat_message/send_message',
    data,
    method: 'post',
  })
}
