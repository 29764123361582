export default {
  namespaced: true,
  state() {
    return {
      cartGoodsNum: 0,
    }
  },
  mutations: {
    SET_CART_GOODS_NUM(state, cartGoodsNum) {
      state.cartGoodsNum = cartGoodsNum;
    },
    ADD_CART_GOODS_NUM(state, cartGoodsNum) {
      state.cartGoodsNum = state.cartGoodsNum + cartGoodsNum;
    },
    DELETE_CART_GOODS_NUM(state, cartGoodsNum) {
      let tempCartGoodsNum = cartGoodsNum > state.cartGoodsNum ? state.cartGoodsNum : cartGoodsNum;
      state.cartGoodsNum = state.cartGoodsNum - tempCartGoodsNum;
    }
  },
  action: {}
}
