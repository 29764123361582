<template>
  <div class="cartList" v-if="!loading && products.length > 0">
    <div class="header acea-row row-middle">
      <!-- <div class="allSelect acea-row row-center-wrapper">
        <el-checkbox v-model="checked">全选</el-checkbox>
      </div> -->
      <div class="info acea-row row-center-wrapper">商品信息</div>
      <div class="price acea-row row-center-wrapper">单价</div>
      <div class="num acea-row row-center-wrapper">数量</div>
      <div class="money acea-row row-center-wrapper">金额</div>
      <div class="operator acea-row row-center-wrapper">操作</div>
    </div>
    <div class="body">
      <div class="item acea-row row-middle" v-for="(item, index) in products" :key="item.id">
        <!-- <div class="allSelect acea-row row-center-wrapper">
          <el-checkbox v-model="checked"></el-checkbox>
        </div> -->
        <div class="info acea-row row-center-wrapper">
          <div class="pictrue">
            <el-image :src="item.pic" :lazy="true" :preview-src-list="[item.pic]">
              <slot name="error">
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </slot>
              <slot name="placeholder">
                <div class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </slot>
            </el-image>
          </div>
          <div class="text">
            <div class="name line2">{{ item.goods_name }}</div>
            <!-- <div class="infor">
              属性：单支粉色永生绣球花，杆长55厘米
            </div> -->
          </div>
        </div>
        <div class="price acea-row row-center-wrapper">{{ item.price }}</div>
        <div class="num acea-row row-center-wrapper">
          <button :class="item.num <= 1 ? 'iconfont icon-shangpinshuliang-jian grey' : 'iconfont icon-shangpinshuliang-jian'" @click="changeNumber(index, -1)"></button>
          <input class="numCon" v-model="item.num" @blur="blurChangeNumber(index)">
          <button class="iconfont icon-shangpinshuliang-jia" @click="changeNumber(index, +1)"></button>
        </div>
        <div class="money acea-row row-center-wrapper font-color">￥{{ parseInt(item.num) * parseFloat(item.price) }}</div>
        <div class="operator acea-row row-center-wrapper">
          <img src="@/assets/images/cart-delete.png" @click="doDeleteCart(index, index)">
        </div>
      </div>
    </div>
  </div>
  <div class="footer acea-row row-between-wrapper" v-if="!loading && products.length > 0">
    <div class="num">已选 {{ cartGoodsNum }} 件商品</div>
    <div class="acea-row row-middle">
      <div class="total">
        合计：
        <span class="font-color">¥{{ totalMoney }}</span>
      </div>
      <div class="btn bg-color" @click="toPreviewOrder">去结算</div>
    </div>
  </div>
  <div class="empty" v-if="!loading && !products.length">
    <img src="@/assets/images/noCart.png" alt="">
    <p>亲，购物车还是空的哟~</p>
    <a herf="havascript:;" class="goIndex" @click="toIndex">继续逛</a>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getCartList, deleteCart, addCart } from '@/api/product'
import { ElCheckbox, ElImage } from 'element-plus';
import 'element-plus/dist/index.css'
import Toast from '@/components/toast/toast'

export default {
  name: 'productPage',
  setup() {
    const store = useStore()
    const router = useRouter()
    const products = ref([])
    const cartGoodsNum = ref(store.getters.cart.cartGoodsNum)
    const totalMoney = ref(0)
    const loading = ref(false)
    const finished = ref(false)
    const queryData = reactive({
      page: 1
    })
    // 获取商品列表
    const getList = async () => {
      loading.value = true
      try {
        const { data } = await getCartList(queryData)
        cartGoodsNum.value = data.totalNum;
        totalMoney.value = data.totalMoney;
        products.value = [...products.value, ...data.list.data]
        queryData.page++
        finished.value = products.value.length >= data.list.total
      } finally {
        loading.value = false
      }
    }

    const doDeleteCart = async (index) => {
      const { data } = await deleteCart({
        id: products.value[index].id
      });
      if (data) {
        store.commit('cart/DELETE_CART_GOODS_NUM', data.num);
        totalMoney.value = totalMoney.value - data.num * products.value[index].price;
        products.value.splice(index, 1);
        Toast({ message: '删除成功' });
      }
    }

    getList();

    const toIndex = async () => {
      await router.push({
        name: 'productPage'
      });
    }

    const toPreviewOrder = async () => {
      await router.push({
        name: 'orderPreview'
      });
    }
    
    // 数量操作
    const changeNumber = (index, step) => {
      const value = parseInt(products.value[index].num) + parseInt(step)
      if(value >= 1) {
        products.value[index].num = value;
        addCart({
          goods_id: products.value[index].goods_id,
          num: products.value[index].num,
          set: 1
        }).then((res) => {
          totalMoney.value = res.data.total_price;
          cartGoodsNum.value = res.data.total_num;
        });
      }
    }

    const blurChangeNumber = (index) => {
      if(products.value[index].num < 1) {
        products.value[index].num = 1;
      }

      addCart({
        goods_id: products.value[index].goods_id,
        num: products.value[index].num,
        set: 1
      }).then((res) => {
        totalMoney.value = res.data.total_price;
        cartGoodsNum.value = res.data.total_num;
      });
    }

    return {
      products,
      cartGoodsNum,
      totalMoney,
      loading,
      finished,
      getList,
      doDeleteCart,
      toIndex,
      toPreviewOrder,
      changeNumber,
      blurChangeNumber
    }
  },
  components: {
    [ElCheckbox.name]: ElCheckbox,
    [ElImage.name]: ElImage,
  }
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: 'iconfont';
  src: url('../../assets/fonts/iconfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.cartList {
  .header {
    height: 54px;
    background: #f9f9f9;
  }

  .body {
    border: 1px solid #efefef;
    border-top: 0;

    .item {
      margin: 40px 0;

      .num {
        .iconfont {
          width: 40px;
          height: 36px;
          line-height: 36px;
          border: 1px solid #d3d3d3;
          text-align: center;
          color: #707070;
          background-color: #fff;
        }

        .numCon {
          width: 54px;
          height: 36px;
          border: 0;
          border-top: 1px solid #d3d3d3;
          border-bottom: 1px solid #d3d3d3;
          font-size: 15px;
          color: #5a5a5a;
          text-align: center;
          line-height: 36px;
          outline: none;
        }
      }
    }

    .operator {
      img {
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
  
  .allSelect {
    width: 100px;
    position: relative;
  }

  .info {
    width: 400px;
    padding-left: 30px;

    .pictrue {
      width: 90px;
      height: 90px;
      margin-right: 14px;
    }

    .text {
      width: 264px;
      
      .infor {
        margin-top: 10px;
        font-size: 12px;
        color: #d0d0d0;
      }
    }
  }

  .price {
    width: 215px;
  }

  .num {
    width: 150px;
  }

  .money {
    width: 270px;
  }
}
.footer {
  height: 82px;
  background: #f9f9f9;
  margin-top: 60px;
  padding-left: 30px;
  margin-bottom: 30px;

  .total {
    font-size: 16px;
  }

  .btn {
    width: 160px;
    height: 82px;
    text-align: center;
    line-height: 82px;
    font-size: 18px;
    color: #fff;
    margin-left: 30px;
    cursor: pointer;
  }
}

.font-color {
  color: #e93323 !important;
}

.bg-color {
  background-color: #e93323 !important
}

.line2, line4 {
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line2 {
  -webkit-line-clamp: 2;
}

.product {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  .goods {
    background-color: white;
    padding: 16px;
    border-radius: 6px;
    .hoverShadow();
    .picture {
      width: 192px;
      height: 192px;
      margin: 0 auto 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .price {
      strong {
        color: red;
        font-weight: bold;
        font-size: 20px;
        margin-right: 10px;
      }
    }
    .title {
      margin: 10px 0;
    }
    .count, del {
      color: #aaa;
      font-size: 12px;
    }
  }
}

.empty {
  text-align: center;
  img {
    width: 350px;
  }
  p {
    font-size: 14px;
    color: #999;
    margin-top: -15px;
  }
  .goIndex {
    margin-top: 10px;
    display: inline-block;
    border-radius: 4px;
    padding: 10px 30px;
    border: 1px solid #282828;
    cursor: pointer;
  }
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-shangpinshuliang-jia, .icon-shangpinshuliang-jia {
  cursor: pointer;
}

.icon-shangpinshuliang-jian:before {
  content: "\e698";
}

.icon-shangpinshuliang-jia:before {
  content: "\e697";
}
</style>