import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import getters from './getters'
import user from './modules/user'
import cart from './modules/cart'
import app from './modules/app'

export default createStore({
  getters,
  modules: {
    user,
    cart,
    app
  },
  plugins: [
    createPersistedState({
      key: 'taiwan-payment-store',
      paths: ['user']
    })
  ]
})
