<template>
  <div class="chat-room" v-show="mVisible" @mouseup="handleHeadMouseUp">
    <div class="room">
      <div class="head" :style="isHeadMouseDown ? 'cursor: move;' : ''" @mousedown="handleHeadMouseDown">
        <div class="image">
          <img src="@/assets/images/kefu.png" />
        </div>
        <div class="name">leo</div>
        <!-- <div class="iconfont icon-shengyinyinliang"></div> -->
        <div class="iconfont icon-guanbi5" @click="close"></div>
      </div>
      <div class="main">
        <div class="chat">
          <div class="record" ref="chatDom">
            <top-infinite-loading
              :loading="loading"
              :finished="finished"
              @infinite="getList"
            >
              <ul>
                <li :class="item.send_user_id > 0 ? 'right' : ''" v-for="(item, index) in mMessageList" :key="index">
                  <div class="time-tag" v-if="item.is_show_time">
                    {{ item.create_time_string }}
                  </div>
                  <div class="avatar" v-if="[1, 4].indexOf(parseInt(item.message_type)) != -1">
                    <img v-if="item.to_user_id <= 0" src="@/assets/images/avatar.png" />
                    <img v-if="item.to_user_id > 0" src="@/assets/images/kefu_avatar.png" />
                  </div>
                  <div class="content" v-if="item.message_type == 1">
                    <div class="text">{{ item.message }}</div>
                  </div>
                  <div class="content" v-if="item.message_type == 4">
                    <div class="image">
                      <img :src="item.message" />
                    </div>
                  </div>
                </li>
              </ul>
            </top-infinite-loading>
          </div>
          <div class="editor">
            <div class="editor-hd">
              <div>
                <!-- <button title="表情" class="emoji-btn">
                  <i class="iconfont icon-biaoqing1"></i>
                </button> -->
                <button title="图片">
                  <div>
                    <el-upload
                      ref="uploadRef"
                      class="upload-image"
                      action="/api/common/upload"
                      :headers="headers"
                      multiple
                      :limit="1"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :before-remove="beforeRemove"
                      :on-exceed="handleExceed"
                      :on-success="handleSuccess"
                      :before-upload="handleBeforeUpload"
                      :file-list="fileList"
                      :show-file-list="false"
                      :disabled="isUpload">
                      <i class="iconfont icon-tupian1"></i>
                    </el-upload>
                  </div>
                </button>
              </div>
              <!-- <div>
                <button>
                  <i clang="iconfont icon-guanji"></i>
                  结束
                </button>
              </div> -->
              <div class="emoji-panel" style="display: none;">
                <i class="em em-tlj-1"></i>
                <i class="em em-tlj-3"></i>
                <i class="em em-tlj-4"></i>
                <i class="em em-tlj-5"></i>
                <i class="em em-tlj-6"></i>
                <i class="em em-tlj-7"></i>
                <i class="em em-tlj-8"></i>
                <i class="em em-tlj-9"></i>
                <i class="em em-tlj-10"></i>
                <i class="em em-tlj-11"></i>
                <i class="em em-tlj-12"></i>
              </div>
            </div>
            <div class="editor-bd">
              <textarea placeholder="请输入文字内容" v-model="currentValue"></textarea>
            </div>
            <div class="editor-ft">
              <button :disabled="currentValue.length <= 0" @click="doSendMessage">发送</button>
            </div>
          </div>
        </div>
        <div class="notice">
          <!-- <div class="rich">
            <p>
              <br />
            </p>
          </div>
          <div class="copy">
            <a href="#" target="_blank">xxx提供技术支持</a>
          </div> -->
        </div>
      </div>
      <audio></audio>
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, nextTick, onUnmounted, onUpdated } from 'vue'
import { ElMessage, ElUpload } from 'element-plus';
import { getIndex, getNotReadIndex, sendMessage } from '@/api/chatMessage'
import TopInfiniteLoading from '@/components/top-infinite-loading'
import { useStore } from 'vuex'

export default {
  name: 'chatBox',
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  emits: ['onClose'],
  setup(props, { emit }) {
    const store = useStore()
    const mMessageList = ref([]);
    const mVisible = ref(Boolean);
    const headers = ref({
      token: store.getters.profile.token
    });
    const fileList = ref([]);
    const isUpload = ref(false);
    const uploadRef = ref(null);
    const currentValue = ref('');
    const showTimeStep = 1800;
    const chatDom = ref(null);
    const isHeadMouseDown = ref(false);
    const loading = ref(false);
    const finished = ref(false);
    let maxId = 0;
    let minId = 0;
    let latePrevTime = 0;
    let flags = 0;

    const FLAGS_INIT = 1;
    const FLAGS_IS_GET_MESSAGE = 2;
    const FLAGS_IS_TO_BOTTOM = 4;

    function timestampToStr(date) {
      let year = date.getFullYear();
      let month = (date.getMonth() + 1).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');
      let hours = date.getHours().toString().padStart(2, '0');
      let minutes = date.getMinutes().toString().padStart(2, '0');
      let seconds = date.getSeconds().toString().padStart(2, '0');
    
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const getList = () => {
      if (loading.value) {
        return;
      }
      loading.value = true;

      let isFirst = false;
      if ((flags & FLAGS_INIT) === 0) {
        flags |= FLAGS_INIT;
        isFirst = true;
      }
      doGetIndex((data) => {
        if (isFirst) {
          startChat();
        }
        loading.value = false;
        if (data.length <= 0) {
          finished.value = true;
        }

        if (isFirst) {
          toBottom();
        }
      }, () => {
        loading.value = false;
      });
    }

    const doGetIndex = (callback, error) => {
      getIndex({
        max_id: maxId
      }).then((res) => {
        if (res.data.length > 0) {
          let prevTime = 0;
          minId = res.data[0].id;
          maxId = res.data[res.data.length - 1].id;
          for (const index in res.data) {
            if (prevTime !== 0 && Math.abs(prevTime - res.data[index].create_time) > showTimeStep) {
              res.data[index].is_show_time = true;
              res.data[index].create_time_string = timestampToStr(new Date(res.data[index].create_time * 1000));
            }
            mMessageList.value.unshift(res.data[index]);
            prevTime = res.data[index].create_time;
            latePrevTime = Math.max(latePrevTime, prevTime);
          }
        }

        callback && callback(res.data);
      }).catch(() => {
        error && error();
      });
    }

    const toBottom = () => {
      setTimeout(() => {
        nextTick(() => {
          if (chatDom.value) {
            chatDom.value.scrollTop = chatDom.value.scrollHeight;
          }
        });
      }, 100);
    }

    const sendMessageEvent = (res) => {
      if (parseInt(res.data.create_time) - latePrevTime > showTimeStep) {
        res.data.is_show_time = true;
        res.data.create_time_string = timestampToStr(new Date(res.data.create_time * 1000));
      } else {
        res.data.is_show_time = false;
      }
      mMessageList.value.push(res.data);
      latePrevTime = res.data.create_time;
      toBottom();
    }

    const doSendMessage = () => {
      if (currentValue.value.length <= 0) {
        ElMessage({
          message: '消息不能为空',
          type: 'error'
        });
        return false;
      }

      let message = currentValue.value;
      currentValue.value = '';

      sendMessage({
        message: message,
        message_type: 1,
      }).then(res => {
        sendMessageEvent(res);
      }).catch(() => {
        if (currentValue.value.length <= 0) {
          currentValue.value = message;
        }
      });
    }

    const handleRemove = (file, fileList) => {
    }

    const handlePreview = (file) => {
    }

    const handleExceed = (files, fileList) => {
      ElMessage({
        message: '超过最大上传数量',
        type: 'error'
      });
      uploadRef.value && uploadRef.value.clearFiles();
    }

    const beforeRemove = (file, fileList) => {
    }

    const handleBeforeUpload = (file) => {
      isUpload.value = true;
      return true;
    }

    const handleSuccess = (response, file, fileList) => {
      sendMessage({
        message: response.data.url,
        message_type: 4,
      }).then(res => {
        sendMessageEvent(res);
        isUpload.value = false;
        uploadRef.value && uploadRef.value.clearFiles();
      }).catch(() => {
        isUpload.value = false;
        uploadRef.value && uploadRef.value.clearFiles();
      });
    }

    const close = () => {
      stopChat();
      emit('onClose')
    }

    const handleHeadMouseDown = () => {
      isHeadMouseDown.value = true;
    }

    const handleHeadMouseUp = () => {
      isHeadMouseDown.value = false;
    }

    const startChat = () => {
      if ((flags & FLAGS_IS_GET_MESSAGE) === 0) {
        flags |= FLAGS_IS_GET_MESSAGE;
        doGetNotReadIndex();
      }
    }

    const doGetNotReadIndex = () => {
      getNotReadIndex({
        min_id: minId
      }).then((res) => {
        if (res.data.length > 0) {
          minId = res.data[res.data.length - 1].id;
          mMessageList.value = mMessageList.value.concat(res.data);
          toBottom();
        }

        setTimeout(() => {
          if ((flags & FLAGS_IS_GET_MESSAGE) === FLAGS_IS_GET_MESSAGE) {
            doGetNotReadIndex();
          }
        }, 1000);
      });
    }

    const stopChat = () => {
      flags = flags & ~FLAGS_IS_GET_MESSAGE
    }
    
    // 监听 props 的变化
    watch(props, () => {
      mVisible.value = props.visible;
      if (mVisible.value) {
        startChat();
        if ((flags & FLAGS_IS_TO_BOTTOM) === 0) {
          flags |= FLAGS_IS_TO_BOTTOM;
          toBottom();
        }
      }
    }, {
      immediate: true
    });

    onMounted(() => {
      toBottom();
    });

    onUnmounted(() => {
      stopChat();
    });

    return {
      mMessageList,
      mVisible,
      headers,
      fileList,
      isUpload,
      uploadRef,
      currentValue,
      chatDom,
      isHeadMouseDown,
      getList,
      loading,
      finished,
      handleRemove,
      handlePreview,
      handleExceed,
      beforeRemove,
      handleBeforeUpload,
      handleSuccess,
      close,
      doSendMessage,
      handleHeadMouseDown,
      handleHeadMouseUp
    }
  },
  components: {
    [ElUpload.name]: ElUpload,
    [TopInfiniteLoading.name]: TopInfiniteLoading

  }
}
</script>

<style lang="less" scoped>
  @font-face {
    font-family: 'iconfont';
    src: url('../../assets/fonts/iconfont.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
  }
  
  .chat-room {
    .room {
      border-radius: 10px;
      position: fixed;
      top: calc(50% - 327px);
      left: calc(50% - 365px);
      z-index: 999;
      display: flex;
      flex-direction: column;
      width: 730px;
      height: 654px;
      background-color: #fff;
      overflow: hidden;
      box-shadow: 1px 1px 15px 0 rgba(0, 0, 0, .1);

      .head {
        display: flex;
        align-items: center;
        height: 50px;
        padding-right: 15px;
        padding-left: 20px;
        background: linear-gradient(270deg, #1890ff, #3875ea);

        .image {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          overflow: hidden;

          img {
            display: block;
            width: 100%;
            height: 100%;
            -o-object-fit: cover;
            object-fit: cover;
          }
        }

        .name {
          flex: 1;
          min-width: 0;
          margin-left: 15px;
          font-size: 16px;
          color: #fff;
        }

        .iconfont {
          width: 25px;
          height: 25px;
          font-size: 16px;
          line-height: 25px;
          text-align: center;
          color: #fff;
          cursor: pointer;
        }
      }

      .main {
        flex: 1;
        display: flex;
        min-height: 0;

        .chat {
          flex: 1;
          display: flex;
          flex-direction: column;
          min-width: 0;
        }

        .record {
          flex: 1;
          min-height: 0;
          overflow-x: hidden;
          overflow-y: auto;

          ul {
            padding: 10px 20px 20px 20px;
          }

          li:after{
            content:"";
            display:block;
            height:0;
            clear:both;
            visibility:hidden;
          }

          li~li {
            margin-top:20px
          }

          li.right {
            .avatar {
              float:right;
            }

            .content {
              text-align:right;
            }

            .content>div {
              text-align:left;
            }
          }

          .time-tag {
            padding-top:10px;
            padding-bottom:30px;
            text-align:center;
            color:#999;
          }
          .avatar {
            float:left;
            width:40px;
            height:40px;
            border-radius:50%;
            overflow:hidden;
          }

          .avatar.right {
            float:right;
          }
          
          .avatar {
            img {
              display:block;
              width:100%;
              height:100%;
              -o-object-fit:cover;
              object-fit:cover;
            }
          }

          .content {
            margin-right:56px;
            margin-left:56px;
          }

          .text {
            display:inline-block;
            min-height:41px;
            padding:10px 12px;
            border-radius:10px;
            background-color:#f5f5f5;
            font-size:15px;
            line-height:21px;
            color:#000;
            word-break:break-all;
          }

          .image {
            display:inline-block;
            max-width:50%;
            border-radius:10px;
            overflow:hidden;

            img {
              display:block;
              max-width:100%;
            }
          }

          .goods,.order {
            display:inline-flex;
            align-items:center;
            width:320px;
            padding:10px 13px;
            border-radius:10px;
            background-color:#f5f5f5;
          }

          .thumb {
            width:60px;
            height:60px;
            border-radius:5px;
            overflow:hidden;

            img{
              display:block;
              width:100%;
              height:100%
            }
          }

          .intro{
            flex:1;
            min-width:0;
            margin-left:10px;

            .name{
              overflow:hidden;
              white-space:nowrap;
              text-overflow:ellipsis;
              font-size:15px;
              color:#000;
            }

            .attr{
              margin-top:5px;
              font-size:12px;
              color:#999;
              
              span{
                vertical-align:middle;
              }

              span~span{
                margin-left:10px;
              }
            }

            .group {
              display:flex;
              justify-content:
              space-between;
              align-items:center;
              margin-top:5px;

              .money{
                font-size:14px;
                color:red;
              }

              a{
                font-size:12px;
                color:#1890ff;
              }
            }
          }
        }

        .editor {
          display:flex;
          flex-direction:column;
          height:162px;
          border-top:1px solid #ececec;

          button {
            border:none;
            background:none;
            outline:none;
          }

          button~button {
            margin-left:20px;
          }

          button.end {
            font-size:15px;
          }

          button:hover,button:hover .iconfont {
            color:#1890ff;
          }

          .editor-hd {
            position:relative;
            display:flex;
            justify-content:space-between;
            align-items:center;
            height:50px;
            padding-right:20px;
            padding-left:20px;

            .iconfont{
              line-height:1;
              color:#333;
            }

            .emoji-panel{
              position:absolute;
              bottom:100%;
              left:5px;
              width:390px;
              padding-bottom:10px;
              border:1px solid #ececec;
              margin-bottom:5px;
              background-color:#fff;
              box-shadow:1px 0 16px 0 rgba(0,0,0,.05);

              .em{
                width:28px;
                height:28px;
                padding:4px;
                margin-top:10px;
                margin-left:10px;
                box-sizing:border-box;
              }
            }
          }

          .editor-bd {
            flex: 1;
            min-height: 0;
    
            textarea {
              display: block;
              width: 100%;
              height: 100%;
              padding-right: 20px;
              padding-left: 20px;
              border: none;
              outline: none;
              resize: none;
              white-space: pre-wrap;
              word-wrap: break-word;
            }
          }

          .editor-ft {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
            padding-bottom: 20px;

            button {
              width: 68px;
              height: 26px;
              border: none;
              border-radius: 3px;
              background-color: #3875ea;
              outline: none;
              font-size: 13px;
              color: #fff;
              cursor: pointer;
            }

            button:disabled {
              background-color: #ccc;
            }
          }

          .icon-biaoqing1 {
            font-size: 22px;
          }

          .icon-guanji {
            margin-right: 5px;
            font-size: 15px;
          }

          .icon-tupian1 {
            font-size: 22px;
          }
        }

        .editor>div:first-child {
          font-size:0;
        }

        .notice {
          display: flex;
          flex-direction: column;
          width: 260px;
          border-left: 1px solid #ececec;

          .copy {
            padding-top: 15px;
            padding-bottom: 15px;
            font-size: 12px;
            text-align: center;
            
            a {
              color: #ccc !important;
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .icon-shengyinyinliang:before {
    content: "\e66a";
  }

  .icon-guanbi5:before {
    content: "\e761";
  }

  .icon-biaoqing1:before {
    content: "\e764";
  }

  .icon-guanji:before {
    content: "\e6ed";
  }

  .icon-tupian1:before {
    content: "\e762";
  }

  .iconfont {
    font-family: "iconfont" !important;
    font-size: 16px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .em.em-tlj-1 {
    background-position: 0 0;
  }

  .em.em-tlj-3 {
    background-position: 0 5%;
  }

  .em.em-tlj-4 {
    background-position: 0 7.5%;
  }

  .em.em-tlj-5 {
    background-position: 0 10%;
  }

  .em.em-tlj-6 {
    background-position: 0 12.5%;
  }

  .em.em-tlj-7 {
    background-position: 0 15%;
  }

  .em.em-tlj-8 {
    background-position: 0 17.5%;
  }

  .em.em-tlj-9 {
    background-position: 0 20%;
  }

  .em.em-tlj-10 {
    background-position: 0 22.5%;
  }

  .em.em-tlj-11 {
    background-position: 0 25%;
  }

  .em.em-tlj-12 {
    background-position: 0 27.5%;
  }

  .em.em-tlj-13 {
    background-position: 0 30%;
  }

  .em.em-tlj-14 {
    background-position: 0 32.5%;
  }

  .em.em-tlj-15 {
    background-position: 0 35%;
  }

  .em.em-tlj-16 {
    background-position: 0 37.5%;
  }

  .em.em-tlj-17 {
    background-position: 0 40%;
  }

  .em.em-tlj-18 {
    background-position: 0 42.5%;
  }

  .em.em-tlj-19 {
    background-position: 0 45%;
  }

  .em.em-tlj-20 {
    background-position: 0 47.5%;
  }

  .em.em-tlj-21 {
    background-position: 0 50%;
  }

  .em.em-tlj-22 {
    background-position: 0 52.5%;
  }

  .em.em-tlj-23 {
    background-position: 0 55%;
  }

  .em.em-tlj-24 {
    background-position: 0 57.5%;
  }

  .em.em-tlj-25 {
    background-position: 0 60%;
  }

  .em.em-tlj-26 {
    background-position: 0 62.5%;
  }

  .em.em-tlj-27 {
    background-position: 0 65%;
  }

  .em.em-tlj-28 {
    background-position: 0 67.5%;
  }

  .em.em-tlj-29 {
    background-position: 0 70%;
  }

  .em.em-tlj-30 {
    background-position: 0 72.5%;
  }
  
  .em.em-tlj-31 {
    background-position: 0 75%;
  }

  .em.em-tlj-32 {
    background-position: 0 77.5%;
  }

  .em.em-tlj-33 {
    background-position: 0 80%;
  }

  .em.em-tlj-34 {
    background-position: 0 82.5%;
  }

  .em.em-tlj-35 {
    background-position: 0 85.5%;
  }

  .em.em-tlj-36 {
    background-position: 0 87.5%;
  }

  .em.em-tlj-37 {
    background-position: 0 90%;
  }

  .em.em-tlj-38 {
    background-position: 0 92.5%;
  }

  .em.em-tlj-39 {
    background-position: 0 95.5%;
  }

  .em.em-tlj-40 {
    background-position: 0 97.5%;
  }

  button, input, select, textarea {
    font: 14px Verdana, Helvetica, Arial, sans-serif;
  }
</style>
