<template>
  <div class="order-detail wrapper_1200">
    <div class="section bag-f">
      <div class="section-hd">订单信息</div>
      <div class="section-bd">
        <ul>
          <li class="acea-row row-middle">
            <div>订单编号：</div>
            <div>{{ orderInfo.order_sn }}</div>
          </li>
          <li class="acea-row row-middle">
            <div>订单日期：</div>
            <div>2024-12-15</div>
          </li>
          <li class="acea-row row-middle">
            <div>支付状态：</div>
            <div>{{ orderInfo.status == 0 ? '待支付' : orderInfo.status == 1 ? '待发货' : orderInfo.status == 2 ? '已发货' :
              orderInfo.status == 3 ? '已完成' : orderInfo.status == 4 ? '退款中' : orderInfo.status == 5 ? '已退款' :
                orderInfo.status == 6 ? '已取消' : '已完成' }}</div>
          </li>
          <li class="acea-row row-middle">
            <div>支付方式：</div>
            <div>{{ (orderInfo.type & 1) == 1 ? '微信' : (orderInfo.type & 2) == 2 ? '支付宝' : '未支付' }}</div>
          </li>
          <li class="acea-row row-middle">
            <div>订单金额：</div>
            <div class="money">￥{{ orderInfo.total_fee }}</div>
          </li>
          <li class="acea-row row-middle" v-if="orderInfo.status === 0">
            <div>剩余时间：</div>
            <div class="money">
              <count-down :time="orderInfo.createtime" @finish="countDownFinish"></count-down>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="section bag-f">
      <div class="section-hd">收货信息</div>
      <div class="section-bd">
        <ul>
          <li class="acea-row row-middle">
            <div>收货人：</div>
            <div>{{ orderInfo.name }}</div>
          </li>
          <li class="acea-row row-middle">
            <div>联系电话：</div>
            <div>{{ orderInfo.mobile }}</div>
          </li>
          <li class="acea-row">
            <div>收货地址：</div>
            <div>{{ orderInfo.address }}</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="section bag-f" v-if="orderInfo.attr_detail && orderInfo.attr_detail.length > 0">
      <div class="section-hd">商品信息</div>
      <div class="section-bd">
        <ul class="goods">
          <li class="acea-row row-middle" v-for="(goodsItem, index) in orderInfo.attr_detail" :key="index"
            @click="$router.push({ name: 'productDetailPage', params: { id: goodsItem.goods_id } })">
            <div>
              <el-image :src="goodsItem.pic" :lazy="true" :preview-src-list="[goodsItem.pic]">
                <slot name="error">
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </slot>
                <slot name="placeholder">
                  <div class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </slot>
              </el-image>
            </div>
            <div>
              <div>{{ goodsItem.goods_name }}</div>
              <div class="info"></div>
              <div class="pice">
                <div>
                  <span class="money">￥{{ goodsItem.price }}</span>
                  <!-- <del>￥299.00</del> -->
                </div>
                <!-- <div class="price-btn">
                                    <div class="split_btn"></div>
                                </div> -->
              </div>
            </div>
          </li>
        </ul>
        <ul>
          <li class="acea-row row-middle">
            <div>运费：0</div>
            <div>包邮</div>
          </li>
        </ul>
        <ul class="total">
          <li class="acea-row row-middle row-between">
            <div>共 {{ orderInfo.cart_num }} 件商品，订单总金额为：<span class="money">￥<b>{{ orderInfo.total_fee }}</b></span>
            </div>
            <div class="footerState acea-row row-middle">
              <div class="orderBnt" v-if="orderInfo.status === 0" @click="doCancelOrder">取消订单</div>
              <div class="orderBnt on" v-if="orderInfo.status === 0"
                @click="$router.push(`/payment/${orderInfo.order_sn}`)">立即付款</div>
              <div class="orderBnt" v-if="orderInfo.status === 2" @click="doConfirmOrder">确认订单</div>
              <div class="orderBnt" @click="doContact">联系客服</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div>
    <chat-box :visible="mVisible" @onClose="onClose" />
  </div>
</template>

<script>
import { ref } from 'vue'
import MessageBox from '@/components/message-box/message'
import { ElImage } from 'element-plus';
import 'element-plus/dist/index.css'
import { useRoute } from 'vue-router'
import { detail, cancelOder, confirm } from '@/api/order'
import ChatBox from '@/components/chat-box'
import CountDown from '@/components/count-down'

export default {
  name: 'orderPreview',
  setup() {
    const route = useRoute();
    // 获取订单详情
    const orderInfo = ref({});
    const mVisible = ref(false);
    detail({ id: route.query.id }).then(res => {
      orderInfo.value = res.data;
    });

    // 取消订单
    const doCancelOrder = () => {
      MessageBox.confirm('确定要取消该订单吗？', '温馨提示', {
        showWarnIcon: true
      }).then(async () => {
        await cancelOder({ order_sn: orderInfo.value.order_sn });
        orderInfo.value.status = 6;
      }).catch(() => { })
    }

    // 确认订单
    const doConfirmOrder = () => {
      MessageBox.confirm('确定要完成该订单吗？', '温馨提示', {
        showWarnIcon: true
      }).then(async () => {
        confirm({
          id: orderInfo.value.id
        }).then((res) => {
          orderInfo.value.status = 3;
        });
      }).catch(() => { })
    }

    const doContact = () => {
      mVisible.value = true;
    }

    const onClose = () => {
      mVisible.value = false;
    }
    
    // 支付剩余时间结束
    const countDownFinish = () => {
      orderInfo.value.status = 6;
    }

    return {
      orderInfo,
      mVisible,
      doCancelOrder,
      doConfirmOrder,
      doContact,
      onClose,
      countDownFinish
    }
  },
  components: {
    [ElImage.name]: ElImage,
    [ChatBox.name]: ChatBox,
    [CountDown.name]: CountDown,
  }
}
</script>

<style lang="less" scoped>
.wrapper_1200 {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}

.order-detail {
  .bag-f {
    background-color: #fff;
    margin: 10px 0;
  }

  .bag-f>div~div {
    border-top: 1px dashed #cecece;
  }

  .bag-f~div {
    margin-top: 14px;
  }

  .bag-f.process {
    margin-top: 0;

    .section-hd {
      padding: 26px 22px 0;
    }

    div {
      border-top: none;

      ul {
        padding: 22px;
        font-size: 16px;
        color: #282828
      }

      li {
        position: relative;
        float: left;
        margin-top: 0;
        margin-left: 222px;
      }

      li:first-child {
        margin-left: 111px;
      }
    }
  }

  .section-hd {
    padding: 18px 22px;
    font-size: 18px;
    color: #282828;
  }

  .section-bd {
    position: relative;

    ul {
      padding: 22px;
      font-size: 16px;
      color: #282828;
    }

    ul~ul {
      border-top: 1px dashed #cecece;
    }

    .goods {
      width: 100%;

      li {
        position: relative;
      }

      li>div:first-child {
        width: 86px;
        height: 86px;
        overflow: hidden;

        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }

      li>div:nth-child(2) {
        margin-right: 26px;
        margin-left: 26px;
      }

      li>div:nth-child(2)>div:first-child {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
      }

      .info {
        font-size: 12px;
        color: #aaa;
        margin-top: 4px;
      }
    }

    .money {
      color: #e93323;

      b {
        font-weight: 400;
        font-size: 22px;
      }
    }

    .total {
      padding: 28px 22px;

      div:first-child {
        width: auto;
      }

      div:last-child {
        flex: none;

        .orderBnt {
          width: 114px;
          height: 40px;
          padding: 0;
          border: 1px solid #999;
          border-radius: 2px;
          background: none;
          outline: none;
          font-size: 16px;
          line-height: 40px;
          text-align: center;
          color: #282828;
        }

        .orderBnt.on {
          border-color: #e93323;
          background: #e93323;
          color: #fff;
        }

        .orderBnt~.orderBnt {
          margin-left: 18px;
        }

        div~div {
          border-left: 1px solid #cecece;
        }

        div {
          padding-right: 10px;
          padding-left: 30px;
        }
      }

      .footerState {
        cursor: pointer;
      }
    }

    li>div:first-child {
      width: 80px;
    }

    li>div:nth-child(2) {
      flex: 1;
    }

    li~li {
      margin-top: 20px;
    }

    li>div:nth-child(2)>div:last-child del {
      margin-left: 12px;
      font-size: 14px;
      color: #919191;
    }
  }
}

.pice {
  display: flex;
  justify-content: space-between;
}

.pice-btn {
  display: flex;
  align-items: center;
}

.split_btn {
  margin-left: 20px;
}
</style>