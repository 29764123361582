<template>
  <div class="layout">
    <header>
      <div class="acea-row row-between-wrapper container">
        <div class="logo">
          <router-link to="/" v-if="system">
            <img :src="system.logo" alt="">
          </router-link>
        </div>
        <ul
          v-if="$route.path !== '/login'"
          class="acea-row menu"
        >
          <li :class="{ active: $route.name === 'productPage' }">
            <router-link to="/product">商品列表</router-link>
          </li>
          <li :class="{ active: $route.name === 'goodsCart' }">
            <router-link to="/goods_cart">购物车<span v-if="$store.getters.cart.cartGoodsNum > 0" :class="$store.getters.cart.cartGoodsNum > 0 ? 'red' : ''">({{$store.getters.cart.cartGoodsNum}})</span></router-link>
          </li>
          <li :class="{ active: $route.name === 'orderPage' }">
            <router-link to="/order">我的订单</router-link>
          </li>
          <li v-if="!$store.getters.profile.token">
            <router-link to="/login">登录/注册</router-link>
          </li>
          <li v-if="$store.getters.profile.token">
            <div class="logout" @click="logout">退出登录</div>
          </li>
        </ul>
      </div>
    </header>
    <main :class="{ whiteBackground: ['goodsCart'].indexOf($route.name) !== -1  }">
      <div class="container">
        <keep-alive v-if="$route.meta.keepAlive">
          <router-view />
        </keep-alive>
        <router-view v-else />
      </div>
    </main>
    <footer class="acea-row row-column-center">
      <template v-if="system">
        <p>
          <span>联系电话：{{system.phone}}</span>
          <span>地址：{{system.address}}</span>
        </p>
        <p><a  href="https://beian.miit.gov.cn/" target="_blank">{{system.beian}}</a></p>
      </template>
    </footer>
    <div class="floatWindow">
      <div class="list">
        <!-- <div class="item">
          <div class="iconfont icon-lianxikefu"></div>
          <div>联系客服</div>
        </div>
        <div class="item">
          <div class="iconfont icon-weixin4"></div>
          <div>关注微信</div>
        </div>
        <div class="item">
          <div class="iconfont icon-cedaohang-gouwuche"></div>
          <div>购物车</div>
        </div> -->
        <div class="item" @click="toTop">
          <div class="iconfont icon-huidaodingbu1"></div>
          <div>回到顶部</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getSystemConfig } from '@/api/system'

export default {
  name: 'layoutPage',
  setup() {
    const router = useRouter()
    const store = useStore();
    const system = ref(null);
    getSystemConfig().then(res => {
      system.value = res.data;
      store.commit('app/SET_APP_INFO', {
        userAgreement: res.data.user_agreement,
        privacyAgreement: res.data.privacy_agreement
      });
      store.commit('cart/SET_CART_GOODS_NUM', res.data.cart_num)
    });

    const logout = () => {
      store.getters.profile.token = '';
      store.commit('cart/SET_PROFILE', store.getters.profile);
      localStorage.removeItem('taiwan-payment-store');
      router.push({
        name: 'loginPage'
      });
    }

    const toTop = () => {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    return {
      system,
      logout,
      toTop
    }
  }
}
</script>

<style lang="less" scoped>
@font-face {
  font-family: 'iconfont';
  src: url('../assets/fonts/iconfont.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

header {
  height: 96px;
  background-color: white;
  border-bottom: 1px solid #eee;
  .logo {
    max-width: 200px;
    height: 80px;
    a, img {
      width: 100%;
      height: 100%;
    }
  }
  .menu {
    li {
      font-size: 16px;
      margin: 0 15px;
      &.active {
        a {
          color: #e93323;
        }
      }
    }
  }
}

main {
  min-height: calc(100vh - 196px);
  padding: 15px 0;
}

.whiteBackground {
  background: #FFFFFF;
}

footer {
  height: 100px;
  background-color: #F2F2F2;
  p {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    margin-left: 40px;
    &:first-child {
      margin-left: 0;
    }
  }
}

.logout {
  cursor: pointer;
}

.red {
  color: #e93323;
}

.floatWindow {
  position: fixed;
  right: 0;
  bottom: 15%;
  width: 70px;
  z-index: 99;
  cursor: pointer;
  background-color: #fff;
  box-shadow: 0 3px 20px rgba(0, 0, 0, .06);

  .list {
    .item {
      position: relative;
      width: 100%;
      height: 74px;
      text-align: center;
      font-size: 12px;
      color: #5c5c5c;
      padding: 12px 0;

      .iconfont {
        margin-bottom: 5px;
        font-size: 25px;
      }
    }

    .item:hover {
      color: #e93323;
    }

    .item~.item:before {
      position: absolute;
      content: " ";
      width: 48px;
      height: 1px;
      background-color: #f0f0f0;
      top: 0;
      left: 50%;
      margin-left: -24px;
    }
  }
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lianxikefu:before {
  content: "\e672";
}

.icon-weixin4:before {
  content: "\e675";
}

.icon-cedaohang-gouwuche:before {
  content: "\e676";
}

.icon-huidaodingbu1:before {
  content: "\e6d4";
}
</style>
