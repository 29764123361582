export default {
  namespaced: true,
  state() {
    return {
      appInfo: {
        userAgreement: '',
        privacyAgreement: ''
      }
    }
  },
  mutations: {
    SET_APP_INFO(state, appInfo) {
      state.appInfo = appInfo;
    }
  },
  action: {}
}
