<template>
    <el-container class="order-preview" style="display: block;">
        <div class="wrapper wrapper_1200 address">
            <div class="title">收货地址</div>
            <div class="form-box">
                <div class="input-item" style="width: 47%; margin-bottom: 20px;">
                    <el-input name="name" v-model="form.name" style="height: 40px;" placeholder="请输入姓名"></el-input>
                </div>
                <div class="input-item" style="margin-left: 3%; width: 47%; margin-bottom: 20px;">
                    <el-input name="mobile" v-model="form.mobile" style="height: 40px;" placeholder="请输入电话"></el-input>
                </div>
                <div class="input-item" style="width: 100%;">
                    <el-input name="address" v-model="form.address" rows="3" type="textarea" placeholder="请输入地址"></el-input>
                </div>
            </div>
        </div>

        <div class="wrapper wrapper_1200 body">
            <div class="title">订单信息</div>
            <div class="order">
                <div class="list" v-for="(item, index) in goods" :key="index">
                    <div class="item acea-row row-between-wrapper op">
                        <div class="txtPic acea-row row-middle">
                            <div class="pictrue">
                                <el-image :src="item.pic" :lazy="true" :preview-src-list="[item.pic]">
                                    <slot name="error">
                                        <div class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </slot>
                                    <slot name="placeholder">
                                        <div class="image-slot">
                                            <i class="el-icon-picture-outline"></i>
                                        </div>
                                    </slot>
                                </el-image>
                            </div>
                            <div class="text">
                                <div class="name line2">{{ item.goods_name }}</div>
                                <div class="info"></div>
                            </div>
                        </div>
                        <div>
                            <span class="money">¥{{ item.price }}</span>
                            <span class="num">x{{ item.num }}</span>
                            <span class="font-color">不送达</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="totalCon">
                <div class="total acea-row row-middle row-right">
                    <div>
                        <span class="font-color">{{ totalNum }}</span>
                        件商品，总商品金额：
                    </div>
                    <div class="money">¥{{ totalMoney }}</div>
                </div>
            </div>
            <div class="totalAmount">
                应付总额：
                <span class="money font-color">¥{{ paidTotalMoney }}</span>
            </div>
            <div class="bnt acea-row row-right">
                <div class="submit bg-color" @click="createOrderSubmit">提交订单</div>
            </div>
        </div>
    </el-container>
    
  </template>
  
  <script>
    import { ref, reactive } from 'vue'
    import { useStore } from 'vuex'
    import { ElContainer, ElEmpty, ElFormItem, ElImage, ElInput, ElMessage } from 'element-plus';
    import 'element-plus/dist/index.css'
    import { useRoute, useRouter } from 'vue-router'
    import { createOrder, prepOrderInfo } from '@/api/order'
  
    export default {
        name: 'orderPreview',
        setup() {
            const store = useStore()
            const route = useRoute();
            const router = useRouter();

            const form = reactive({
                productId: route.query.productId,
                address: route.query.address,
                name: route.query.name,
                mobile: route.query.mobile,
                cartNum: route.query.cartNum
            });

            const goods = ref([]);
            const totalNum = ref(1);
            const totalMoney = ref(0);
            const paidTotalMoney = ref(0);
            prepOrderInfo({
                product_id: route.query.productId,
                num: route.query.cartNum
            }).then(res => {
                console.log(res.data)
                form.address = res.data.address;
                form.name = res.data.name;
                form.mobile = res.data.mobile;
                goods.value = res.data.goods
                totalNum.value = res.data.total_num;
                totalMoney.value = res.data.total_money;
                paidTotalMoney.value = res.data.paid_total_money;
            }).catch(() => {
                
            });

            // 创建订单
            const createOrderSubmit = async () => {
                const { data } = await createOrder({ 
                    productId: form.productId, 
                    cartNum: form.cartNum,
                    name: form.name,
                    mobile: form.mobile,
                    address: form.address,
                });
                if (data) {
                    store.commit('cart/SET_CART_GOODS_NUM', 0);
                    await router.push(`/payment/${data.order_sn}`)
                } else {
                    ElMessage({
                        message: '创建订单失败',
                        type: 'error'
                    })
                }
            }

            return {
                goods,
                form,
                totalNum,
                totalMoney,
                paidTotalMoney,
                createOrderSubmit
            }
        },
        components: {
            [ElContainer.name]: ElContainer,
            [ElImage.name]: ElImage,
            [ElEmpty.name]: ElEmpty,
            [ElInput.name]: ElInput,
            [ElFormItem.name]: ElFormItem
        }
    }
  </script>
  
  <style lang="less" scoped>
    .address {
        padding-bottom: 30px;
        .form-box {
            padding: 0 28px;
            
            .input-item {
                display: inline-block;
            }
        }
    }

    .body {
        margin-top: 10px;
        padding-bottom: 56px;
    }

    .wrapper {
        background-color: #fff;
    }

    .wrapper_1200 {
        width: 1200px;
        overflow: hidden;

        .title {
            height: 64px;
            line-height: 64px;
            padding: 0 28px;
            font-size: 18px;
        }

        .order {
            width: 1160px;
            margin: 0 auto;
            border: 1px solid #efefef;
            padding: 26px 32px;

            .item{
                .money {
                    font-size: 16px;
                }

                .num {
                    margin-left: 6px;
                }

                .font-color {
                    font-size: 16px;
                    font-weight: 700;
                    display: inline-block;
                    width: 130px;
                    text-align: right;
                }
            }
        }

        .totalCon {
            padding: 27px 46px;

            .money {
                width: 120px;
                text-align: right;
                font-size: 16px;
            }
        }

        .totalAmount {
            width: 1160px;
            height: 70px;
            line-height: 70px;
            background: #f7f7f7;
            text-align: right;
            padding-right: 22px;
            margin: 0 auto;
        }

        .bnt {
            margin: 38px 20px 0 0;
            cursor: pointer;

            .submit {
                width: 180px;
                height: 46px;
                border-radius: 4px;
                font-size: 16px;
                color: #fff;
                text-align: center;
                line-height: 46px;
            }

            .bg-color-hui {
                background-color: #bbb;
            }

            .bg-color {
                background-color: #e93323 !important;
            }
        }
    }

    .font-color {
        color: #e93323 !important;
    }
  </style>