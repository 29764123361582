<template>
  <infinite-loading
    :loading="loading"
    :finished="finished"
    @infinite="getList"
  >
    <div class="product">
      <router-link
        :to="`/product/detail/${item.id}`"
        class="goods"
        v-for="item in products"
        :key="item.id"
      >
        <div class="picture">
          <img :src="item.absolute_pic" alt="">
        </div>
        <div class="price">
          <strong>￥{{item.price}}</strong>
          <del>￥{{item.original_price}}</del>
        </div>
        <div class="title ell2">{{item.goods_name}}</div>
        <div class="count">{{item.sales_volume}}人付款</div>
      </router-link>
    </div>
  </infinite-loading>
  <div class="empty" v-if="!loading && !products.length">
    <img src="@/assets/images/empty.svg" alt="">
    <p>暂无商品哦~</p>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { getProducts } from '@/api/product'
import InfiniteLoading from '@/components/infinite-loading'

export default {
  name: 'productPage',
  setup() {
    // 获取商品列表
    const products = ref([])
    const productTotal = ref(0)
    const loading = ref(false)
    const finished = ref(false)
    const queryData = reactive({
      page: 1,
      limit: 15
    })
    const getList = async () => {
      loading.value = true
      try {
        const { data } = await getProducts(queryData)
        products.value = [...products.value, ...data.data]
        productTotal.value = data.total
        queryData.page++
        finished.value = products.value.length >= data.total
      } finally {
        loading.value = false
      }
    }

    return {
      products,
      productTotal,
      loading,
      finished,
      getList
    }
  },
  components: {
    [InfiniteLoading.name]: InfiniteLoading
  }
}
</script>

<style lang="less" scoped>
.product {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  .goods {
    background-color: white;
    padding: 16px;
    border-radius: 6px;
    .hoverShadow();
    .picture {
      width: 192px;
      height: 192px;
      margin: 0 auto 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .price {
      strong {
        color: red;
        font-weight: bold;
        font-size: 20px;
        margin-right: 10px;
      }
    }
    .title {
      margin: 10px 0;
    }
    .count, del {
      color: #aaa;
      font-size: 12px;
    }
  }
}
.empty {
  text-align: center;
  img {
    width: 350px;
  }
  p {
    font-size: 14px;
    color: #999;
    margin-top: -15px;
  }
}
</style>
